<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <div class="sidebar">
              <span class="sidebar-heading">Links</span>
              <cmsBlockSimple identifier="side-menu-cms" />
            </div>
          </b-col>
          <b-col lg="9">
            <h1>{{ cmsPageContent.title }}</h1>
            <VRuntimeTemplate
              v-if="cmsPageContent.content"
              :template="`<div class='cms-content'>${cmsPageContent.content}</div>`"
            ></VRuntimeTemplate>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_thehague_fietskledingvoordeel/core/components/BaseLayout";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";

import Breadcrumbs from "@/base/core/components/Breadcrumbs";
import VRuntimeTemplate from "v-runtime-template";
export default {
  name: "StaticPage",
  components: {
    BaseLayout,
    Breadcrumbs,
    cmsBlockSimple,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
    };
  },

  data: () => ({}),
};
</script>
